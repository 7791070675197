<div class="date-picker-container">
  @if (showBootstrapDatepicker) {
    <ngb-datepicker #datePicker [outsideDays]="outsideDays"
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="displayMonths" [dayTemplate]="dayTemplate"
      [minDate]="_convertDateToNgbDate(minDate)" [maxDate]="_convertDateToNgbDate(maxDate)">
    </ngb-datepicker>
  }

  <ng-template #dayTemplate let-date let-month="currentMonth" let-focused="focused" let-disabled="disabled">
    <span
      class="ngb-calendar-day"
      [class.day-disabled]="disabled"
      [class.faded]="date.month !== month"
      [class.focused]="focused"
      [class.selected]="isSelected(date)"
      [class.hovered]="isHovered(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null">
      {{ date.day }}
    </span>
  </ng-template>

  @if (!showBootstrapDatepicker && rangeType === 'month') {
    <div class="month-selector">
      <div class="year-selection-row">
        <img class="increment-button flipped" src="/assets/i/chevron-down-dark.svg" (click)="onYearSelection(selectedYear - 1)"/>
        <select class="select-ctrl" [(ngModel)]="selectedYear" (ngModelChange)="onYearSelection($event)">
          @for (year of allYears; track year) {
            <option [value]="year">{{ year }}</option>
          }
        </select>
        <img class="increment-button" src="/assets/i/chevron-down-dark.svg" (click)="onYearSelection(selectedYear + 1)"/>
      </div>
      <div class="months-flex-container">
        @for (month of allMonths; track month; let i = $index) {
          <div class="month-pill"
            (click)="onMonthSelection(i)" [class.selected]="selectedMonth === i">
            {{ month }}
          </div>
        }
      </div>
    </div>
  }

  @if (!showBootstrapDatepicker && rangeType === 'year') {
    <div class="year-selector">
      @for (year of allYears; track year) {
        <div class="year-pill"
          (click)="onYearSelection(year)" [class.selected]="selectedYear === year">
          {{ year }}
        </div>
      }
    </div>
  }

</div>
