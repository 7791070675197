<div class="csp-filter-container">
  <div fxLayout="row" class="buttons-height">
    <div class="selectedFilters">
      <button #button cdkOverlayOrigin #trigger="cdkOverlayOrigin"
        [ngClass]="isFullScreen? 'full-window' : 'minimized-window'"
        [fxFlex]="filterStyles?.button ? filterStyles.button : 30" (click)="toggleOverlay()" id="download-template-button"
        mat-button>
        <div>
          <span class="mat-filter-icon1">
            <span class="vertical-align-middle-filter-icon">
              <img height="16px" width="16px" src="/assets/i/Filters.svg" />
            </span>
            @if (isFullScreen) {
              <span class="icon-alignment">&nbsp;Filters</span>
            }
          </span>
          <span class="filter-length-icon icon-alignment">@if (!isFullScreen) {
            <span>&nbsp;</span>
          }
          {{totalLength}} / {{totalOptionLength}}
          @if (!isFullScreen) {
            <span></span>
          }</span>
        </div>
      </button>
      <div [fxFlex]="filterStyles?.space ? filterStyles.space : 50"></div>
      @if (isFullScreen || alwaysShowFilterPillBoxes) {
        <div class="filter-pill-boxes-container" [class.display-flex]="finalList.length < 4" [class.display-block]="finalList.length > 4">
          @for (option of finalList; track option) {
            @switch (pillBoxSpecificity) {
              @case ('values') {
                @for (value of finalValue[option]; track value) {
                  <span class="pr-10">
                    <a class="selected-name-bg-color">
                      <div>{{ dictionary[option]?.value | APItoUImappingFilter:filterAPICallType: projectId: dictionary[option]?.value }}</div>
                      <div>: {{ value }}</div>
                      <mat-icon class="mat-icon-font-size" (click)="removeValueFromFilter(option, null)">close</mat-icon>
                    </a>
                  </span>
                }
              }
              @case ('fields') {
                <span class="pr-10">
                  <a class="selected-name-bg-color">
                    <div>{{ dictionary[option]?.value | APItoUImappingFilter:filterAPICallType: projectId: dictionary[option]?.value }}</div>
                    <mat-icon class="mat-icon-font-size" (click)="removeFieldFromFilter(option)">close</mat-icon>
                  </a>
                </span>
              }
            }
          }
        </div>
      }
    </div>
    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
      (backdropClick)="closeDialog()" #filterTemplate>
      <div class="flex-container">
        <div class="filter-book">
          <div class="flex-item main-list">
            <div class="left-align-forced">
              <mat-selection-list (selectionChange)="onSelection($event, shoes.selectedOptions.selected[0]?.value)"
                class="list1" #shoes [multiple]="false">
                @for (option of filterOptions; track option) {
                  <mat-list-option class="mat-list-option-1 flow-root-display"
                    [value]="option.key">
                    <span title="{{option.value | APItoUImappingFilter:filterAPICallType: projectId :option?.value  }}">{{option.value | APItoUImappingFilter:filterAPICallType: projectId :option?.value  }}
                    </span> <span class="float-right-display">{{rightSelectedValues[option.key]?
                    rightSelectedValues[option.key]?.length : 0}} /
                  {{getObjectLength(option.key)}} </span>
                </mat-list-option>
              }
            </mat-selection-list>
          </div>
        </div>
        <div class="ver-line">
        </div>
        <div class="flex-item">
          @if (showSearchBox) {
          <div class="left-align-forced search-field search-flex">
            <div style="border: 1px solid black; margin: 3px; padding: 4px;position:fixed;z-index: 999;">
              <!-- <mat-form-field class="input-box-form-field-1" [floatLabel]="'never'"> -->
                <input class="input-search" autocomplete="off" id="searchText" #search1 matInput placeholder="Search..." type="text"
                  (keyup)="filteredValues = search(search1.value);" />
                <span class="vertical-align-middle-filter-icon" button mat-button matSuffix
                  mat-icon-button><img class="searchIcon"
                src="/assets/i/search.svg" /></span>
                <!-- <button mat-button *ngIf="search1.value" matSuffix mat-icon-button aria-label="Clear"
                (click)="search1.value = ''; search(search1.value);">
                <mat-icon>close</mat-icon>
              </button> -->
            <!-- </mat-form-field> -->
          </div>
          <br><br>
        </div>
      } @else {
          <div #search1></div>
      }
          @if(selectedValue && selectedValue =='RiskLevel'){
          <div class="risk-level">
            <div class="risk-level-div">
              <div class="title-name title-name-risk-level">Risk Level</div>
              <div class="risk-level-inputs">
                <input type="text"  [(ngModel)]="riskSliderMinVal" autocomplete="off"  class="risk-level-input"/>
                <span class="inputs-between">  - </span>
                <input type="text" [(ngModel)]="riskSliderMaxVal" autocomplete="off"  class="risk-level-input"/>
              </div>
            </div>
            <div class="custom-slider-range">
             <app-custom-slider-range [rangeSliderMinVal]="riskSliderMinVal" [rangeSliderMaxVal]="riskSliderMaxVal" (changingMinVal)="getRiskMinVal($event)" (changingMaxVal)="getRiskMaxVal($event)"></app-custom-slider-range>
            </div>
          </div>
          }
          @else if (selectedValue && selectedValue =='TaskPriority') {
            <div class="risk-level">
              <div class="risk-level-div">
                <div class="title-name title-name-risk-level">Priority</div>
                <div class="risk-level-inputs">
                  <input type="text"  [(ngModel)]="prioritySliderMinVal" class="risk-level-input"/>
                  <span class="inputs-between">  - </span>
                  <input type="text" [(ngModel)]="prioritySliderMaxVal" class="risk-level-input"/>
                </div>
              </div>              
              <div class="custom-slider-range">
                <app-custom-slider-range  [rangeSliderMinVal]="prioritySliderMinVal" [rangeSliderMaxVal]="prioritySliderMaxVal" (changingMinVal)="getPriorityMinVal($event)" (changingMaxVal)="getPriorityMaxVal($event)"></app-custom-slider-range>
               </div>
            </div>
          }
          @else if (selectedValue && selectedValue =='DateRanges') {
            <div class="date-range">
                 <div class="title-name date-range-title">Date category</div>
                 <div  class="date-range-div">
                  <div  class="date-range-multi-select">
                    <mat-form-field appearance="outline" class="custom-select1" style="position: absolute; width: 290px;">
                      <mat-select [formControl]="dateRangeOptions" [panelClass]="custom-panel" multiple>
                       <mat-select-trigger>
                         {{dateRangeOptions.value?.[0] || ''}}
                         @if ((dateRangeOptions.value?.length || 0) > 1) {
                           <span class="example-additional-selection">
                             (+{{(dateRangeOptions.value?.length || 0) - 1}} {{dateRangeOptions.value?.length === 2 ? 'other' : 'others'}})
                           </span>
                         }
                       </mat-select-trigger>
                       @for (item of dateRangeOptionlist.menuValues; track option) {
                        <mat-option class="options-custom-style"  [value]="item">{{ item }}</mat-option>
                       }
                     </mat-select> 
                   </mat-form-field> 
                  </div>
                 </div>
                 @if(dateRangeOptions.value.length > 0){
                 <div class="title-name title-name-sub-div">Date range</div>
                <div class="date-range-select-div">
                  <div class="custom-date-selection">
                    <div class="star-date-label" >From</div>
                    <app-date-input-box 
                        class="calender-input-start"
                        [date]="startDate"
                        (dateChange)="getSelectedSatrtDate($event)">
                    </app-date-input-box>
                  </div>
                <div>
                  <div class="end-date-label">To</div>      
                  <app-date-input-box 
                    class="calender-input-end"
                    [date]="endDate"
                    [maxDate]="startDate"
                    (dateChange)="getSelectedEndDate($event)">
                  </app-date-input-box>
                </div>
                </div>
                <div class="select-area-div">
                  <div  class="cutomize-button" [ngClass]="dateRangeSeleaction['1'] ? 'cutomize-button-active' : 'cutomize-button-inactive'" (click)="setDateRange('1')">Today</div>
                  <div  class="cutomize-button" [ngClass]="dateRangeSeleaction['7'] ? 'cutomize-button-active' : 'cutomize-button-inactive'" (click)="setDateRange('7')">Last 7 days</div>
                  <div  class="cutomize-button" [ngClass]="dateRangeSeleaction['28'] ? 'cutomize-button-active' : 'cutomize-button-inactive'" (click)="setDateRange('28')">Last 28 days</div>
                  <div  class="cutomize-button" [ngClass]="dateRangeSeleaction['90'] ? 'cutomize-button-active' : 'cutomize-button-inactive'" (click)="setDateRange('90')">Last 90 days</div>
                  <div  class="cutomize-button" [ngClass]="dateRangeSeleaction['365'] ? 'cutomize-button-active' : 'cutomize-button-inactive'" (click)="setDateRange('365')">Last year</div>
                  <div  class="cutomize-button" [ngClass]="dateRangeSeleaction['custom'] ? 'cutomize-button-active' : 'cutomize-button-inactive'" (click)="setDateRange('custom')">Custom range</div>
                </div>
              }
            </div>
          }
          @else if (selectedValue && selectedValue =='OverDueTasks') {
            <div class="overdue-task">
              <div class="title-name title-name-overdue">Overdue tasks</div>
                <div class="select-boxes">
                  <div class="select-box">
                    <label class="label-name">Pending for</label>
                     <select class="select-ctrl" [(ngModel)]="selectedOverDueCondition" (ngModelChange)="selectedOverDue($event)">
                      <option class="option-class" value="">Select</option>
                      <option class="option-class" value="moreThan">More than</option>
                      <option class="option-class" value="lessThan" >Less than</option>
                    </select>
                   </div>
                    <div class="select-box">
                      <label class="label-name">days</label>
                      <select class="select-ctrl" [(ngModel)]="selectedOverDueValue" (ngModelChange)="selectedOverDue($event)">
                        <option class="option-class" value="">Select</option>
                        <option  class="option-class"[value]="1">1 Day</option>
                        <option class="option-class" [value]="7">1 Week</option>
                        <option class="option-class" [value]="30">1 Month</option>
                        <option class="option-class" [value]="365">1 Year</option>
                      </select>
                    </div>  
                 </div>
             </div>  
          }
          @else {
            @if (selectedValue && selectedValue =='States') {
              <div class="title-name states-title">States</div>
            }
        <div class="mat-selection-flex">
          <mat-selection-list
            (selectionChange)="onMultipleSelection($event, shoes1.selectedOptions.selected,selectedValue)"
            class="list2 check-box-list" #shoes1>
            @if (getErrorNoRecords()) {
              <mat-error class="no-records-error"><div>No records found.</div></mat-error>
            }
            @for (item of selectedList | keyvalue; track item) {
              <mat-list-option #optionName [attr.title]="item.value" [selected]="getSelectedValues(item.value,selectedValue)" style="line-height: 1;"
                class="mat-list-option-2" checkboxPosition="before"
                [value]="item.value" matTooltipClass="list-option-title-tool-tip" matTooltip="{{ item.value }}"
                >
                <span> {{item.value}}</span>
              </mat-list-option>
            }
          </mat-selection-list>
        </div>
        }
      </div>
    </div>
    <hr style="width:100%;text-align:center;margin:0%">
    <div class="flex-container" style="height:8vh;border: 0px;justify-content: flex-end;align-items: center;width:100%">
      <div style="display: contents;">
        <button mat-button fxLayoutAlign="flex-start" class="semi-bold clear-filter-button"
          (click)="shoes1.deselectAll(); shoes.deselectAll(); clearFilter()" fxFlex="20">
          Clear filters
        </button>
      </div>

      <div><button (click)="this.isOpen = false;" class="semi-bold cancel-button" mat-button>
        Cancel
      </button>
    </div>
    <div style="padding-right: 12px; color:rgb(131, 106, 240);">
      <button (click)="applyFilter()" class="apply-button" color="primary" mat-raised-button>
        Apply
      </button>
    </div>
  </div>
</div>
</ng-template>
</div>
