import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import moment from 'moment';
@Component({
  selector: 'app-date-input-box',
  templateUrl: './date-input-box.component.html',
  styleUrls: ['./date-input-box.component.scss']
})
export class DateInputBoxComponent implements OnChanges {

  @Input() date: Date;
  @Input() format: string = 'dd MMM yyyy';
  @Input() placeholder: string = 'Select date';
  @Input() showErrors: boolean;
  @Input() height: number = 32;
  @Input() disable: boolean;
  @Input() disablePicker: boolean;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() maxDateRange: Date;
  @Input() minDateRange: Date;
  @Input() minDateError: string;
  @Input() maxDateError: string;
  @Input() dateRange:any;
  @Input() type:any;
  @Input() exceedLimit:boolean;
  @Input() isOutageTask:boolean;
  public viewMode: 'day' | 'isoWeek' | 'month' | 'year' | 'custom' = 'custom';
  @Output() dateChange = new EventEmitter();
  @Output() dateRangeChange = new EventEmitter();
  @Output() executionDateChangeError = new EventEmitter<string>;
  public showPicker: boolean;
  public validationError: string;
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if(!changes.disabled){
      this.validate();
      this.dateRangeChange.emit([this.dateRange,this.validationError]);
    }
  }

  public validate() {
    if(this.type == 'range' && this.dateRange) {
      this.maxDateRange = new Date(this.maxDateRange);
      this.minDateRange = new Date(this.minDateRange);
      this.dateRange.start = new Date(this.dateRange.start);
      this.dateRange.end = new Date(this.dateRange.end);
      if (!this.dateRange.start || !this.dateRange.end || this.dateRange.start == "Invalid date" || this.dateRange.end == "Invalid date") { 
        return this.validationError = 'Field is required';
      }
    if (this.dateRange.end > this.maxDateRange && moment(this.dateRange.end).format('YYYY-MM-DD') != moment(this.maxDateRange).format('YYYY-MM-DD')) {
      return this.validationError = this.maxDateError || `Date cannot be after ${moment(this.maxDateRange).format('DD MMM YYYY')}`;
    }
    if (this.dateRange.start < this.minDateRange && moment(this.dateRange.start).format('YYYY-MM-DD') != moment(this.minDateRange).format('YYYY-MM-DD')) {
      return this.validationError = this.minDateError || `Date cannot be before ${moment(this.minDateRange).format('DD MMM YYYY')}`;
    }
  } else if(this.type == 'day'){
    this.maxDate = new Date(this.maxDate);
    this.minDate = new Date(this.minDate);
    this.date = new Date(this.date);
      if (this.exceedLimit && this.isOutageTask) {
        this.executionDateChangeError.emit(this.validationError);
        return this.validationError = 'Execution date should be future date and within outage window timeline'
      } else if (this.exceedLimit && !this.isOutageTask) {
        this.executionDateChangeError.emit(this.validationError);
        return this.validationError = 'Execution date should be future date and within event timeline'
      }
    if (!this.date) {
      this.executionDateChangeError.emit(this.validationError);
      return this.validationError = 'Field is required'; 
    }
    if (this.maxDate && this.date > this.maxDate && moment(this.date).format('YYYY-MM-DD') != moment(this.maxDate).format('YYYY-MM-DD')) {
      this.executionDateChangeError.emit(this.validationError);
      return this.validationError = this.maxDateError || `Date cannot be after ${moment(this.maxDate).format('DD MMM YYYY')}`;
    }
    if (this.minDate && this.date < this.minDate && moment(this.date).format('YYYY-MM-DD') != moment(this.minDate).format('YYYY-MM-DD')) {
      this.executionDateChangeError.emit(this.validationError);
      return this.validationError = this.minDateError || `Date cannot be prior to ${moment(this.minDate).format('DD MMM YYYY')}`;
    }
    this.executionDateChangeError.emit(this.validationError = null);

  } else {
    if (!this.date) { return this.validationError = 'Field is required'; }
    if (this.maxDate && this.date > this.maxDate) {
      return this.validationError = this.maxDateError || `Date cannot be after ${moment(this.maxDate).format('DD MMM YYYY')}`;
    }
    if (this.minDate && this.date < this.minDate) {
      return this.validationError = this.minDateError || `Date cannot be prior to ${moment(this.maxDate).format('DD MMM YYYY')}`;
    }
  }
    return this.validationError = null;
  }
  onDateRangeChange(dateRange: { start: Date, end: Date }) {
    this.dateRange = dateRange;
    this.validate();
    this.dateRangeChange.emit([this.dateRange,this.validationError]);
  }
}
