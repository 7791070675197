import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { GridsterModule } from 'angular-gridster2';
import { ResizableModule } from 'angular-resizable-element';
import { DragulaModule } from 'ng2-dragula';
import { MatSliderModule } from '@angular/material/slider';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { DirectivesModule } from 'src/app/directive/directives.module';
import { StartsWithPipe } from '../directive/startWith.directive';
import { AddNewComponent } from './add-new/add-new.component';
import { AddOutageComponent } from './add-outage/add-outage.component';
import { AdvancedSelectComponent } from './advanced-select/advanced-select.component';
import { AssetInfoComponent } from './asset-info/asset-info.component';
import { AssignMemberToTeamComponent } from './assign-member-to-team/assign-member-to-team.component';
import { BarChartHorizontalComponent } from './bar-chart-horizontal/bar-chart-horizontal.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { BarHorizontalStackComponent } from './bar-horizontal-stack/bar-horizontal-stack.component';
import { BudgetChartBarComponent } from './budget-chart-bar/budget-chart-bar.component';
import { BudgetPlannerComponent } from './budget-planner/budget-planner.component';
import { BudgetRiskMatrixComponent } from './budget-risk-matrix/budget-risk-matrix.component';
import { ClusterColumnChartComponent } from './cluster-column-chart/cluster-column-chart.component';
import { ComboChartComponent } from './combo-chart/combo-chart.component';
import { IrregularChartComponent } from './irregular-chart/irregular-chart.component';
import { ComponentDataComponent } from './component-data/component-data.component';
import { EditWidgetDashboardComponent } from './dashboard/widgets/edit-widget-dashboard/edit-widget-dashboard.component';
import { EditWidgetDashboardURLComponent } from './dashboard/widgets/edit-widget-dashboard-url/edit-widget-dashboard-url.component';
import { EditComboWidgetModalComponent } from './dashboard/widgets/edit-widget-dashboard/modals/edit-combo-widget-modal/edit-combo-widget-modal.component';
import { PresetTabMenuComponent } from './dashboard/widgets/edit-widget-dashboard/preset-tab-menu/preset-tab-menu.component';
import { WidgedDashboardHeaderComponent } from './dashboard/widgets/edit-widget-dashboard/widged-dashboard-header/widged-dashboard-header.component';
import { PerformanceWidgetsComponent } from './dashboard/widgets/performance-widgets.component';
import { BarWidgetComponent } from './dashboard/widgets/widget-components/bar-widget/bar-widget.component';
import { ClusterColumnWidgetComponent } from './dashboard/widgets/widget-components/cluster-column-widget/cluster-column-widget.component';
import { ColumnWidgetComponent } from './dashboard/widgets/widget-components/column-widget/column-widget.component';
import { ComboChartWidgetComponent } from './dashboard/widgets/widget-components/combo-chart-widget/combo-chart-widget.component';
import { DisplayWidgetComponent } from './dashboard/widgets/widget-components/display-widget/display-widget.component';
import { GaugeWidgetComponent } from './dashboard/widgets/widget-components/gauge-widget/gauge-widget.component';
import { LineWidgetComponent } from './dashboard/widgets/widget-components/line-widget/line-widget.component';
import { PieChartWidgetComponent } from './dashboard/widgets/widget-components/pie-chart-widget/pie-chart-widget.component';
import { StackColumnWidgetComponent } from './dashboard/widgets/widget-components/stack-column-widget/stack-column-widget.component';
import { WidgetInstanceComponent } from './dashboard/widgets/widget-instance/widget-instance.component';
import { DeleteAlertModalComponent } from './delete-alert-modal/delete-alert-modal.component';
import { ExcutionCalendarComponent } from './excution-calendar/excution-calendar.component';
import { ExecutionToolComponent } from './execution-tool/execution-tool.component';
import { FlagOutageComponent } from './flag-outage/flag-outage.component';
import { GaugeComponent } from './gauge/gauge.component';
import { GroupBarChartComponent } from './group-bar-chart/group-bar-chart.component';
import { GroupedBarChartComponent } from './grouped-bar-chart/grouped-bar-chart.component';
import { HazardMatrixComponent } from './hazard-matrix/hazard-matrix.component';
import { InformationExplorerSideComponent } from './information-explorer-side/information-explorer-side.component';
import { SystemNotificationsComponent } from './main-header/modal/system-notifications/system-notifications.component';
import { MapComponent } from './map/map.component';
import { CreateConnectionModalComponent } from './map/modal/create-connection-modal/create-connection-modal.component';
import { CreateJointModalComponent } from './map/modal/create-joint-modal/create-joint-modal.component';
import { CreateLoadModalComponent } from './map/modal/create-load-modal/create-load-modal.component';
import { CreateSourceModalComponent } from './map/modal/create-source-modal/create-source-modal.component';
import { CreateSubstationModalComponent } from './map/modal/create-substation-modal/create-substation-modal.component';
import { MemberComponent } from './member/member.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { PlanningThreeStateSlideSelectComponent } from './planning-three-state-slide-select/three-state-slide-select.component';
import { PlanningToolComponent } from './planning-tool/planning-tool.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { ResistanceChartComponent } from './resistance-chart/resistance-chart.component';
import { RiskMatrixHistoryComponent } from './risk-matrix-history/risk-matrix-history.component';
import { RiskMatrixComponent } from './risk-matrix/risk-matrix.component';
import { AddSubsystemItemComponent } from './subsystem/modals/add-subsystem-item/add-subsystem-item.component';
import { AddSubsystemComponent } from './subsystem/modals/add-subsystem/add-subsystem.component';
import { RemoveItemComponent } from './subsystem/modals/remove-item/remove-item.component';
import { SubSystemComponent } from './subsystem/sub-systems/sub-system.component';
import { ThreeStateSlideSelectComponent } from './three-state-slide-select/three-state-slide-select.component';
import { UserComponent } from './users/users.component';
import { ValidatorWrapperComponent } from './validators/validator-wrapper/validator-wrapper.component';
import { BackWithoutSaveAlertModalComponent } from 'src/app/containers/dashboard/modals/back-without-save-alert-modal/back-without-save-alert-modal.component';
import { FailurepopupComponent } from './../containers/create-swms/modal/failurepopup/failurepopup.component';
import { AlertExecutionComponent } from '../containers/execution/modals/alert-execution/alert-execution.component';
import { ReportDashboardComponent } from './../components/reports/dashboard/report-dashboard.component';
import { WidgetCommentModalComponent } from './dashboard/widgets/edit-widget-dashboard/modals/widget-comment-modal/widget-comment-modal.component';
import { TwoDigitDecimaNumberDirectiveDirective } from '../containers/policy/edit-policy/assessment/two-digit-decima-number-directive.directive';
import { CancelCiModalComponent } from '../containers/execution/modals/cancel-ci-modal/cancel-ci-modal.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CspCommonFilterComponent } from './csp-common-filter/csp-common-filter.component';
import { FullscreenOverlayContainer, OverlayContainer, OverlayModule,} from '@angular/cdk/overlay';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import {APItoUImappingFilterPipe} from '../Pipes/APItoUImappingFilter';
import { DecimalDelimiterPipe } from '../Pipes/decimalDelimiter';
import { NumbersOnlyDirective } from '../directive/NumbersOnly.directive';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { ColumnFilterComponent } from './generic-table/column-filter/column-filter.component';
import {EmptyValuePipe} from './pipes/empty-value.pipe';
import { HoverButtonComponent } from './hover-button/hover-button.component';
import { BlueDotComponent } from './blue-dot/blue-dot.component';
import { IconSeverityComponent } from './main-header/alarm-item/icon-severity/icon-severity.component';
import { HeaderIconSeverityComponent } from './main-header/header-icon-severity/header-icon-severity.component';
import { AssignUserComponent } from '../containers/execution/modals/assign-user/assign-user.component';
import { GenericTableComponentAIFComponent } from './generic-table-component-aif/generic-table-component-aif.component';
import { PerfectScrollbarModule } from './perfect-scrollbar-wrapper/perfect-scrollbar.module';
import { AddExistingInstallationModalComponent } from './map/modal/add-existing-installation-modal/add-existing-installation-modal/add-existing-installation-modal.component';
import { VirtualPaginationTableComponent } from './virtual-pagination-table/virtual-pagination-table.component';
import { TitleCellComponent } from './virtual-pagination-table/title-cell/title-cell.component';
import { ColumnSettingsComponent } from './column-settings/column-settings.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DurationPipe } from 'src/app/Pipes/duration.pipe';
import { DateInputBoxComponent } from './date-input-box/date-input-box.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { GroupingConfigurationComponent } from './virtual-pagination-table/grouping-configuration/grouping-configuration.component';
import { MultiActionButtonComponent } from './multi-action-button/multi-action-button.component';
import { ToggleIconsModule } from './toggle-icons/toggle-icons.module';
import { FormStepsProgressComponent } from './form-steps-progress/form-steps-progress.component';
import { AndNMoreTextOverflowComponent } from './and-n-more-text-overflow/and-n-more-text-overflow.component';
import { ShoppingCartTableComponent } from './shopping-cart-table/shopping-cart-table.component';
import { DragHelperComponent } from './drag-helper/drag-helper.component';
import { BasicInformationTab } from '../containers/create-swms/basic-information-tab/basic-information-tab';
import { ResourceRequirementsComponent } from '../containers/create-swms/resource-requirements/resource-requirements.component';
import { GroupTableComponent } from '../containers/create-swms/resource-requirements/group-table/group-table.component';
import { HorizontalImageGalleryComponent } from './horizontal-image-gallery/horizontal-image-gallery.component';
import { PopperDirective } from '../directive/popper.directive';
import { ImagesUploadFieldComponent } from './images-upload-field/images-upload-field.component';
import { PlancompleteAlertModalComponent } from '../containers/dashboard/modals/plancomplete-alert-modal/plancomplete-alert-modal.component';
import { PreventScrollDirective } from '../directive/preventScroll.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CustomSliderRangeComponent } from './custom-slider-range/custom-slider-range.component';


declare global {
    interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}
@NgModule({
  declarations: [
    GenericTableComponent,
    NumbersOnlyDirective,
    StartsWithPipe,
    DeleteAlertModalComponent,
    AlertExecutionComponent,
    CancelCiModalComponent,
    AddNewComponent,
    MemberComponent,
    ComponentDataComponent,
    AssetInfoComponent,
    PlanningToolComponent,
    ExecutionToolComponent,
    ExcutionCalendarComponent,
    ProgressBarComponent,
    AddOutageComponent,
    ThreeStateSlideSelectComponent,
    BudgetPlannerComponent,
    BudgetChartBarComponent,
    PlanningThreeStateSlideSelectComponent,
    RiskMatrixComponent,
    BudgetRiskMatrixComponent,
    FlagOutageComponent,
    BarChartComponent,
    MapComponent,
    CreateSubstationModalComponent,
    CreateConnectionModalComponent,
    CreateSourceModalComponent,
    CreateLoadModalComponent,
    CreateJointModalComponent,
    BarChartHorizontalComponent,
    GroupBarChartComponent,
    ClusterColumnChartComponent,
    AssignMemberToTeamComponent,
    UserComponent,
    ComboChartComponent,
    IrregularChartComponent,
    ResistanceChartComponent,
    HazardMatrixComponent,
    RiskMatrixHistoryComponent,
    PieChartComponent,
    SystemNotificationsComponent,
    InformationExplorerSideComponent,
    ValidatorWrapperComponent,
    GroupedBarChartComponent,
    AdvancedSelectComponent,
    SubSystemComponent,
    AddSubsystemComponent,
    AddSubsystemItemComponent,
    RemoveItemComponent,
    BarHorizontalStackComponent,
    GaugeComponent,
    PerformanceWidgetsComponent,
    EditWidgetDashboardComponent,
    EditWidgetDashboardURLComponent,
    WidgedDashboardHeaderComponent,
    WidgetInstanceComponent,
    PresetTabMenuComponent,
    DisplayWidgetComponent,
    ComboChartWidgetComponent,
    PieChartWidgetComponent,
    ClusterColumnWidgetComponent,
    LineWidgetComponent,
    EditComboWidgetModalComponent,
    WidgetCommentModalComponent,
    StackColumnWidgetComponent,
    ColumnWidgetComponent,
    GaugeWidgetComponent,
    BarWidgetComponent,
    FailurepopupComponent,
    BackWithoutSaveAlertModalComponent,
    ReportDashboardComponent,
    TwoDigitDecimaNumberDirectiveDirective,
    APItoUImappingFilterPipe,
    DecimalDelimiterPipe,
    ColumnFilterComponent,
    EmptyValuePipe,
    HoverButtonComponent,
    BlueDotComponent,
    IconSeverityComponent,
    HeaderIconSeverityComponent,
    AssignUserComponent,
    GenericTableComponentAIFComponent,
    CspCommonFilterComponent,
    AddExistingInstallationModalComponent,
    VirtualPaginationTableComponent,
    TitleCellComponent,
    ColumnSettingsComponent,
    DatePickerComponent,
    DurationPipe,
    DateInputBoxComponent,
    TimePickerComponent,
    GroupingConfigurationComponent,
    MultiActionButtonComponent,
    FormStepsProgressComponent,
    AndNMoreTextOverflowComponent,
    ShoppingCartTableComponent,
    DragHelperComponent,
    BasicInformationTab,
    ResourceRequirementsComponent,
    GroupTableComponent,
    HorizontalImageGalleryComponent,
    ImagesUploadFieldComponent,
    PlancompleteAlertModalComponent,
    CustomSliderRangeComponent
  ],
  exports: [
    GenericTableComponent,
    NgbModule,
    NgxDatatableModule,
    StartsWithPipe,
    AddNewComponent,
    MemberComponent,
    ComponentDataComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    AssetInfoComponent,
    PlanningToolComponent,
    ThreeStateSlideSelectComponent,
    BudgetPlannerComponent,
    PlanningThreeStateSlideSelectComponent,
    RiskMatrixComponent,
    BudgetRiskMatrixComponent,
    BudgetChartBarComponent,
    MapComponent,
    BarChartComponent,
    BarChartHorizontalComponent,
    ExecutionToolComponent,
    ExcutionCalendarComponent,
    ProgressBarComponent,
    GroupBarChartComponent,
    ClusterColumnChartComponent,
    GroupedBarChartComponent,
    AssignMemberToTeamComponent,
    UserComponent,
    ComboChartComponent,
    IrregularChartComponent,
    ResistanceChartComponent,
    HazardMatrixComponent,
    RiskMatrixHistoryComponent,
    PieChartComponent,
    AdvancedSelectComponent,
    InformationExplorerSideComponent,
    ValidatorWrapperComponent,
    BarHorizontalStackComponent,
    GaugeComponent,
    SubSystemComponent,
    PerformanceWidgetsComponent,
    EditWidgetDashboardComponent,
    EditWidgetDashboardURLComponent,
    WidgetInstanceComponent,
    BackWithoutSaveAlertModalComponent,
    FailurepopupComponent,
    DeleteAlertModalComponent,
    TwoDigitDecimaNumberDirectiveDirective,
    PopperDirective,
    APItoUImappingFilterPipe,
    DecimalDelimiterPipe,
    EmptyValuePipe,
    HoverButtonComponent,
    BlueDotComponent,
    IconSeverityComponent,
    HeaderIconSeverityComponent,
    AssignUserComponent,
    GenericTableComponentAIFComponent,
    CspCommonFilterComponent,
    VirtualPaginationTableComponent,
    ColumnSettingsComponent,
    DatePickerComponent,
    DurationPipe,
    DateInputBoxComponent,
    TimePickerComponent,
    MultiActionButtonComponent,
    FormStepsProgressComponent,
    AndNMoreTextOverflowComponent,
    ShoppingCartTableComponent,
    DragHelperComponent,
    BasicInformationTab,
    ResourceRequirementsComponent,
    GroupTableComponent,
    HorizontalImageGalleryComponent,
    ImagesUploadFieldComponent,
    PlancompleteAlertModalComponent,
    PreventScrollDirective,
    CustomSliderRangeComponent
  ],
  imports: [
    NgxDatatableModule,
    NgbModule,
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule.forChild([]),
    ContextMenuModule.forRoot(),
    ReactiveFormsModule,
    PerfectScrollbarModule,
    MatSliderModule,
    // ToastrModule.forRoot({
    //   positionClass: 'toast-top-center',
    //   closeButton: true,
    //   enableHtml: true,
    //   timeOut: 20000,
    //   preventDuplicates: true,
    //   maxOpened: 1
    // }),
    DragulaModule.forRoot(),
    DirectivesModule,
    GridsterModule,
    ResizableModule,
    NgSelectModule,
    NgxPaginationModule,
    OverlayModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule, 
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgbDatepickerModule,
    ToggleIconsModule
  ],
  providers: [
    {
      provide: OverlayContainer,
      useClass: FullscreenOverlayContainer
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA]
})
export class SharedModule {
}
