<div>
<div id="RangeSlider" #rangeSlider class="range-slider">
	<div>
		<div class="range-slider-val-left"></div>
		<div class="range-slider-val-right"></div>
		<div class="range-slider-val-range"></div>

		<span class="range-slider-handle range-slider-handle-left"></span>
		<span class="range-slider-handle range-slider-handle-right"></span>

		<div class="range-slider-tooltip range-slider-tooltip-left range-slider-tooltip-color-green">
			<span class="range-slider-tooltip-text"></span>
		</div>

		<div class="range-slider-tooltip range-slider-tooltip-right range-slider-tooltip-color-red">
			<span class="range-slider-tooltip-text"></span>
		</div>
	</div>

	<input type="range" #leftInput [(ngModel)]="rangeSliderMinVal" (ngModelChange)="getSliderInputValues('leftInput')" class="range-slider-input-left" tabindex="0" max="100" min="0" step="1">
	<input type="range" #rightInput [(ngModel)]="rangeSliderMaxVal" (ngModelChange)="getSliderInputValues('rightInput')" class="range-slider-input-right" tabindex="0" max="100" min="0" step="1">
</div>

<div class="range-scale">
	<div>|</div>
	<div>|</div>
	<div>|</div>
	<div>|</div>
	<div>|</div>
	<div>|</div>
 </div>

 <div class="range-scale-numbers">
	<div>0</div>
	<div>20</div>
	<div>40</div>
	<div>60</div>
	<div>80</div>
	<div>100</div>
 </div>
</div>