@if (loading) {
  <div class="loading-box">
    <div class="img" alt="loading"></div>
  </div>
}

<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title" i18n>{{title}}</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
    </button>
  </div>
  <form>
    <div class="modal-body">
      <div class="about-container" [class.clear-bottom]="!this.isAboutPanelOpen">
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          <div class="tasks-email-container">
            <div class="title">Tasks</div>
            <div class="email-option email-style" style="margin-left: 80px !important;">
              <input id="enableTasksEmail" type="checkbox" [checked]="tasks"
                (change)="onChangeTasksChecked($event, 'tasks', 'enable')">
              <span>Email notification</span>
            </div>
            <div class="email-switch" [class.active]="tasks" [class.disabled]="!tasks"
              (click)="tasks && clickedInside($event, 'tasksDropdown')">
              <div class="selected">
                @if (this.data.tasks.email.option != null) {
                <p>{{this.data.tasks.email.option}}</p>
                }
                @if (this.data.tasks.email.option == null) {
                <p>Once an hour</p>
                }
                <i class="icon icon-dropdown" aria-hidden="true" [class.up]="emailPopup"></i>
              </div>
              @if (emailPopup) {
              <div *ngIf="activeDropdown === 'tasksDropdown'" class="email-popup">
                @for (item of emailOptions; track item) {
                <a href="javascript:;" (click)="changeEmailOption(item,'tasksDropdown')"
                  [class.hidden]="this.data.tasks.email.option === item">
                  {{item}}
                </a>
                }
              </div>
              }
            </div>
          </div>
        </div>

        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          @if (isSystemRole()) {
          <div style="cursor: not-allowed;pointer-events: none;" class="option">
            <span>New tasks (assigned to me)</span>
            <label for="enableNewTasks" class="checkbox-switch" [class.checked]="getStatus('tasks', 'enableNewTasks')">
              <input id="enableNewTasks" type="checkbox" [checked]="getStatus('tasks', 'enableNewTasks')"
                (change)="onChangeChecked($event, 'tasks', 'enableNewTasks')">
              <i class="icon icon-switchbox" aria-hidden="true"
                style="cursor: not-allowed;pointer-events: none;background-color:darkgray;"
                [ngClass]="{'checked': getStatus('tasks', 'enableNewTasks')}"></i>
            </label>
          </div>
          }
          @if (!isSystemRole()) {
          <div class="option">
            <span>New tasks (assigned to me)</span>
            <label for="enableNewTasks" class="checkbox-switch" [class.checked]="getStatus('tasks', 'enableNewTasks')">
              <input id="enableNewTasks" type="checkbox" [checked]="getStatus('tasks', 'enableNewTasks')"
                (change)="onChangeChecked($event, 'tasks', 'enableNewTasks')">
              <i class="icon icon-switchbox" aria-hidden="true"
                [ngClass]="{'checked': getStatus('tasks', 'enableNewTasks')}"></i>
            </label>
          </div>
          }
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          @if (isSystemRole()) {
          <div style="cursor: not-allowed;pointer-events: none;" class="option">
            <span>Overdue tasks</span>
            <label for="enableOverdueTasks" class="checkbox-switch"
              [class.checked]="getStatus('tasks', 'enableOverdueTasks')">
              <input id="enableOverdueTasks" type="checkbox" [checked]="getStatus('tasks', 'enableOverdueTasks')"
                (change)="onChangeChecked($event, 'tasks', 'enableOverdueTasks')">
              <i class="icon icon-switchbox" aria-hidden="true"
                style="cursor: not-allowed;pointer-events: none;background-color:darkgray;"
                [ngClass]="{'checked': getStatus('tasks', 'enableOverdueTasks')}"></i>
            </label>
          </div>
          }
          @if (!isSystemRole()) {
          <div class="option">
            <span>Overdue tasks</span>
            <label for="enableOverdueTasks" class="checkbox-switch"
              [class.checked]="getStatus('tasks', 'enableOverdueTasks')">
              <input id="enableOverdueTasks" type="checkbox" [checked]="getStatus('tasks', 'enableOverdueTasks')"
                (change)="onChangeChecked($event, 'tasks', 'enableOverdueTasks')">
              <i class="icon icon-switchbox" aria-hidden="true"
                [ngClass]="{'checked': getStatus('tasks', 'enableOverdueTasks')}"></i>
            </label>
          </div>
          }
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          @if (isSystemRole()) {
          <div style="cursor: not-allowed;pointer-events: none;" class="option">
            <span>Rejected or Cancelled tasks</span>
            <label for="enableRejectedTasks" class="checkbox-switch"
              [class.checked]="getStatus('tasks', 'enableRejectedTasks')">
              <input id="enableRejectedTasks" type="checkbox" [checked]="getStatus('tasks', 'enableRejectedTasks')"
                (change)="onChangeChecked($event, 'tasks', 'enableRejectedTasks')">
              <i class="icon icon-switchbox" aria-hidden="true"
                style="cursor: not-allowed;pointer-events: none;background-color:darkgray;"
                [ngClass]="{'checked': getStatus('tasks', 'enableRejectedTasks')}"></i>
            </label>
          </div>
          }
          @if (!isSystemRole()) {
          <div class="option">
            <span>Rejected or Cancelled tasks</span>
            <label for="enableRejectedTasks" class="checkbox-switch"
              [class.checked]="getStatus('tasks', 'enableRejectedTasks')">
              <input id="enableRejectedTasks" type="checkbox" [checked]="getStatus('tasks', 'enableRejectedTasks')"
                (change)="onChangeChecked($event, 'tasks', 'enableRejectedTasks')">
              <i class="icon icon-switchbox" aria-hidden="true"
                [ngClass]="{'checked': getStatus('tasks', 'enableRejectedTasks')}"></i>
            </label>
          </div>
          }
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          @if (isSystemRole()) {
          <div style="cursor: not-allowed;pointer-events: none;" class="option no-bottom">
            <span>Validated tasks</span>
            <label for="enableValidatedTasks" class="checkbox-switch"
              [class.checked]="getStatus('tasks', 'enableValidatedTasks')">
              <input id="enableValidatedTasks" type="checkbox" [checked]="getStatus('tasks', 'enableValidatedTasks')"
                (change)="onChangeChecked($event, 'tasks', 'enableValidatedTasks')">
              <i class="icon icon-switchbox" aria-hidden="true"
                style="cursor: not-allowed;pointer-events: none;background-color:darkgray;"
                [ngClass]="{'checked': getStatus('tasks', 'enableValidatedTasks')}"></i>
            </label>
          </div>
          }
          @if (!isSystemRole()) {
          <div class="option no-bottom">
            <span>Validated tasks</span>
            <label for="enableValidatedTasks" class="checkbox-switch"
              [class.checked]="getStatus('tasks', 'enableValidatedTasks')">
              <input id="enableValidatedTasks" type="checkbox" [checked]="getStatus('tasks', 'enableValidatedTasks')"
                (change)="onChangeChecked($event, 'tasks', 'enableValidatedTasks')">
              <i class="icon icon-switchbox" aria-hidden="true"
                [ngClass]="{'checked': getStatus('tasks', 'enableValidatedTasks')}"></i>
            </label>
          </div>
          }
        </div>
      </div>
      <div class="about-container" [class.clear-bottom]="!this.isAboutPanelOpen">
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          <div class="tasks-email-container">
            <div class="title">Performance</div>
            <div class="email-option email-style" style="margin-left: 40px !important;">
              <input id="enablePerformanceEmail" type="checkbox" [checked]="performance" 
                (change)="onChangePerformanceChecked($event, 'performance', 'enable')">
              <span>Email notification</span>
            </div>
            <div class="email-switch" [class.active]="performance" [class.disabled]="!performance"
              (click)="performance && clickedInside($event,'performanceDropdown')">
              <div class="selected">
                @if (this.data.performance.email.option != null) {
                <p>{{this.data.performance.email.option}}</p>
                }
                @if (this.data.performance.email.option == null) {
                <p>Once an hour</p>
                }
                <i class="icon icon-dropdown" aria-hidden="true" [class.up]="emailPopup"></i>
              </div>
              @if (emailPopup) {
              <div *ngIf="activeDropdown === 'performanceDropdown'" class="email-popup">
                @for (item of emailOptions; track item) {
                <a href="javascript:;" (click)="changeEmailOption(item,'performanceDropdown')"
                  [class.hidden]="this.data.performance.email.option === item">
                  {{item}}
                </a>
                }
              </div>
              }
            </div>
          </div>
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          @if (isSystemRole()) {
          <div style="cursor: not-allowed;pointer-events: none;" class="option">
            <span>Substation risk levels</span>
            <label for="enableSubstationRiskLevels" class="checkbox-switch"
              [class.checked]="getStatus('performance', 'enableSubstationRiskLevels')">
              <input id="enableSubstationRiskLevels" type="checkbox"
                [checked]="getStatus('performance', 'enableSubstationRiskLevels')"
                (change)="onChangeChecked($event, 'performance', 'enableSubstationRiskLevels')">
              <i class="icon icon-switchbox" aria-hidden="true"
                style="cursor: not-allowed;pointer-events: none;background-color:darkgray;"
                [ngClass]="{'checked': getStatus('performance', 'enableSubstationRiskLevels')}"></i>
            </label>
          </div>
          }
          @if (!isSystemRole()) {
          <div class="option">
            <span>Substation risk levels</span>
            <label for="enableSubstationRiskLevels" class="checkbox-switch"
              [class.checked]="getStatus('performance', 'enableSubstationRiskLevels')">
              <input id="enableSubstationRiskLevels" type="checkbox"
                [checked]="getStatus('performance', 'enableSubstationRiskLevels')"
                (change)="onChangeChecked($event, 'performance', 'enableSubstationRiskLevels')">
              <i class="icon icon-switchbox" aria-hidden="true"
                [ngClass]="{'checked': getStatus('performance', 'enableSubstationRiskLevels')}"></i>
            </label>
          </div>
          }

        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          @if (isSystemRole()) {
          <div style="cursor: not-allowed;pointer-events: none;" class="option">
            <span>Asset risk levels</span>
            <label for="enableAssetRiskLevels" class="checkbox-switch"
              [class.checked]="getStatus('performance', 'enableAssetRiskLevels')">
              <input id="enableAssetRiskLevels" type="checkbox"
                [checked]="getStatus('performance', 'enableAssetRiskLevels')"
                (change)="onChangeChecked($event, 'performance', 'enableAssetRiskLevels')">
              <i class="icon icon-switchbox" aria-hidden="true"
                style="cursor: not-allowed;pointer-events: none;background-color:darkgray;"
                [ngClass]="{'checked': getStatus('performance', 'enableAssetRiskLevels')}"></i>
            </label>
          </div>
          }
          @if (!isSystemRole()) {
          <div class="option">
            <span>Asset risk levels</span>
            <label for="enableAssetRiskLevels" class="checkbox-switch"
              [class.checked]="getStatus('performance', 'enableAssetRiskLevels')">
              <input id="enableAssetRiskLevels" type="checkbox"
                [checked]="getStatus('performance', 'enableAssetRiskLevels')"
                (change)="onChangeChecked($event, 'performance', 'enableAssetRiskLevels')">
              <i class="icon icon-switchbox" aria-hidden="true"
                [ngClass]="{'checked': getStatus('performance', 'enableAssetRiskLevels')}"></i>
            </label>
          </div>
          }
        </div>
      </div>
      <div class="about-container" [class.clear-bottom]="!this.isAboutPanelOpen">
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          <div class="tasks-email-container">
            <div class="title">Account and system</div>
            <div class="email-option email-style">
              <input id="enableAccountEmail" type="checkbox" [checked]="systemAndAccount" 
                (change)="onChangeAccountChecked($event, 'systemAndAccount', 'enable')">
              <span>Email notification</span>
            </div>
            <div class="email-switch" [class.active]="systemAndAccount" [class.disabled]="!systemAndAccount"
              (click)="systemAndAccount && clickedInside($event,'accountDropdown')">
              <div class="selected">
                @if (this.data.systemAndAccount.email.option != null) {
                <p>{{this.data.systemAndAccount.email.option}}</p>
                }
                @if (this.data.systemAndAccount.email.option == null) {
                <p>Once an hour</p>
                }
                <i class="icon icon-dropdown" aria-hidden="true" [class.up]="emailPopup"></i>
              </div>
              @if (emailPopup) {
              <div *ngIf="activeDropdown === 'accountDropdown'" class="email-popup">
                @for (item of emailOptions; track item) {
                <a href="javascript:;" (click)="changeEmailOption(item,'accountDropdown')"
                  [class.hidden]="this.data.systemAndAccount.email.option === item">
                  {{item}}
                </a>
                }
              </div>
              }
            </div>
          </div>
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          <div class="option">
            <span>Account updates</span>
            <label for="enableAccountUpdates" class="checkbox-switch"
              [class.checked]="getStatus('systemAndAccount', 'enableAccountUpdates')">
              <input id="enableAccountUpdates" type="checkbox"
                [checked]="getStatus('systemAndAccount', 'enableAccountUpdates')"
                (change)="onChangeChecked($event, 'systemAndAccount', 'enableAccountUpdates')">
              <i class="icon icon-switchbox" aria-hidden="true"
                [ngClass]="{'checked': getStatus('systemAndAccount', 'enableAccountUpdates')}"></i>
            </label>
          </div>
        </div>
        <div class="row" [class.hide]="!this.isAboutPanelOpen">
          <div class="option">
            <span>System events</span>
            <label for="enableSystemEvents" class="checkbox-switch"
              [class.checked]="getStatus('systemAndAccount', 'enableSystemEvents')">
              <input id="enableSystemEvents" type="checkbox"
                [checked]="getStatus('systemAndAccount', 'enableSystemEvents')"
                (change)="onChangeChecked($event, 'systemAndAccount', 'enableSystemEvents')">
              <i class="icon icon-switchbox" aria-hidden="true"
                [ngClass]="{'checked': getStatus('systemAndAccount', 'enableSystemEvents')}"></i>
            </label>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" i18n (click)="onSubmit()">Save</button>
    </div>
  </form>
</div>