import { Directive, ElementRef, HostListener, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { createPopper, Options } from '@popperjs/core';


@Directive({
  selector: '[appPopper]'
})
export class PopperDirective implements OnChanges {

  @Input('appPopperElement') popperElement: HTMLElement;
  @Input('appPopperOptions') popperOptions: Partial<import('@popperjs/core').Options> = {};
  @Input('appPopperShow') show: boolean;

  @Output() clickedOutside: EventEmitter<void> = new EventEmitter();

  private popper: import('@popperjs/core').Instance | null = null;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.show) {
      this.show ? this.createPopperInstance() : this.destroyPopperInstance();
    }

    if (changes.popperElement && this.popperElement) {
      this.popperElement.style.display = 'none';
    }
  }

  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target: HTMLElement): void {
    if (
      this.popper &&
      !this.elementRef.nativeElement.contains(target) &&
      !this.popperElement.contains(target)
    ) {
      this.clickedOutside.emit();
    }
  }

  private destroyPopperInstance(): void {
    if (!this.popper) { return; }
    this.popper.destroy();
    this.popper = null;
    this.popperElement.style.display = 'none';
    this.popperElement.style.animation = 'none';
  }

  private createPopperInstance(): void {
    this.popper?.destroy();

    const defaultOptions: Partial<Options> = {
      placement: 'bottom-start',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 3]
          }
        },
        {
          name: 'adaptive',
          enabled: false,
        },
      ]
    };

    const options = { ...defaultOptions, ...this.popperOptions };

    this.popper = createPopper(this.elementRef.nativeElement, this.popperElement, options);
    this.popperElement.style.display = 'block';
    this.popperElement.style.animation = 'fadeInDelay50 0.2s';

    setTimeout(() => this.popper?.update());
  }

  ngOnDestroy(): void {
    // this.destroyPopperInstance();
  }
}
