import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, EventEmitter, Output, SimpleChanges, OnChanges, HostListener, Renderer2, TemplateRef, ViewChildren } from '@angular/core';

@Component({
	selector: 'app-custom-slider-range',
	templateUrl: './custom-slider-range.component.html',
	styleUrl: './custom-slider-range.component.scss'
})
export class CustomSliderRangeComponent implements AfterViewInit, OnChanges {
	
	@ViewChild('rangeSlider') rangeSlider!: ElementRef;
	@ViewChild('leftInput') leftInput: ElementRef<HTMLInputElement>;
	@ViewChild('rightInput') rightInput: ElementRef<HTMLInputElement>;

	@Input()rangeSliderMinVal:number = 0;
	@Input()rangeSliderMaxVal:number = 100;

    @Output()changingMinVal  = new EventEmitter<number>();
	@Output()changingMaxVal  = new EventEmitter<number>()

	constructor(private renderer: Renderer2) {}


	ngOnChanges(changes: SimpleChanges): void {
		if(changes['rangeSliderMinVal']){
			this.rangeSliderMinVal = changes['rangeSliderMinVal'].currentValue;
			setTimeout(() => this.getSliderInputValues('leftInput'), 0);
		}
		if(changes['rangeSliderMaxVal']){
			this.rangeSliderMaxVal = changes['rangeSliderMaxVal'].currentValue;
			setTimeout(() => this.getSliderInputValues('rightInput'), 0);
		}
	
	}

	ngAfterViewInit() {
      this.defaultConfigurations();
	}
	
	getSliderInputValues(changingInput){
		//left side slider range input
        if(changingInput == 'leftInput'){
          this.leftInputRangeChange();
		}
		//right side slider range
		else{
			this.rightInputRangeChange();
		}
        
	}

	getValue(targetValue,e){
		return (100 / (parseInt(e.max) - parseInt(e.min))) * targetValue - (100 / (parseInt(e.max) - parseInt(e.min))) * parseInt(e.min);
	}
    
	leftInputRangeChange(){
		let e = this.leftInput.nativeElement;
		let targetValue:number = Math.min( parseInt(e.value), parseInt(this.rightInput.nativeElement.value) - 1);
		let value = this.getValue(targetValue, e);
		let children = e.parentNode.childNodes[0].childNodes;
        this.renderer.setStyle(children[0],'width',`${value}%`);
		this.renderer.setStyle(children[2],'left',`${value}%`);
		this.renderer.setStyle(children[3],'left',`${value}%`);
		this.renderer.setStyle(children[5],'left',`${value}%`);
		this.renderer.setProperty(children[5].childNodes[0], "innerHTML",targetValue);
		this.changingMinVal.emit(targetValue);
		this.changeTooltipBGColor(e,children,'left');
	}
    
	rightInputRangeChange(){
		let e = this.rightInput.nativeElement;
		let targetValue:number = Math.max( parseInt(e.value), parseInt(this.leftInput.nativeElement.value) - (-1));
		let value = this.getValue(targetValue, e);
		let children = e.parentNode.childNodes[0].childNodes;
        this.renderer.setStyle(children[1],'width',`${100 - value}%`);
		this.renderer.setStyle(children[2],'right',`${100 - value}%`);
		this.renderer.setStyle(children[4],'left',`${value}%`);
		this.renderer.setStyle(children[6],'left',`${value}%`);
		this.renderer.setProperty(children[6].childNodes[0], "innerHTML",targetValue);
		this.changingMaxVal.emit(targetValue);
		this.changeTooltipBGColor(e,children,'right');
	}

	changeTooltipBGColor(e,children,inputType){
		const container = this.rangeSlider.nativeElement;	
		let classAndIndex = this.getClassNameAndIndex(inputType);		
			if (e.value >= 0 && 33 >= e.value) {
				this.renderer.setStyle(children[classAndIndex.indexNo],"backgroundColor","#478B1A");
				this.renderer.setStyle(children[classAndIndex.indexNo].childNodes[0],"backgroundColor","#478B1A");
				this.renderer.setStyle(children[classAndIndex.indexNo].childNodes[0],"color","#FFFFFF");
				container.querySelector('#RangeSlider .'+classAndIndex.className).classList.remove('range-slider-tooltip-color-black',
					'range-slider-tooltip-color-red', 'range-slider-tooltip-color-yellow');
				container.querySelector('#RangeSlider .'+classAndIndex.className).classList.add('range-slider-tooltip-color-green');
			}
			else if (e.value > 33 && 66 >= e.value) {
				this.renderer.setStyle(children[classAndIndex.indexNo],"backgroundColor","#FFD966");
				this.renderer.setStyle(children[classAndIndex.indexNo].childNodes[0],"backgroundColor","#FFD966");
				this.renderer.setStyle(children[classAndIndex.indexNo].childNodes[0],"color","#414141");
				container.querySelector('#RangeSlider .'+classAndIndex.className).classList.remove('range-slider-tooltip-color-black',
					'range-slider-tooltip-color-red', 'range-slider-tooltip-color-green');
				container.querySelector('#RangeSlider .'+classAndIndex.className).classList.add('range-slider-tooltip-color-yellow');
			}
			else if (e.value > 66 && 100 >= e.value) {
				this.renderer.setStyle(children[classAndIndex.indexNo],"backgroundColor","#FF0026");
				this.renderer.setStyle(children[classAndIndex.indexNo].childNodes[0],"backgroundColor","#FF0026");
				this.renderer.setStyle(children[classAndIndex.indexNo].childNodes[0],"color","#FFFFFF");
				container.querySelector('#RangeSlider .'+classAndIndex.className).classList.remove('range-slider-tooltip-color-black',
					'range-slider-tooltip-color-yellow', 'range-slider-tooltip-color-green');
				container.querySelector('#RangeSlider .'+classAndIndex.className).classList.add('range-slider-tooltip-color-red');
			}
	}

	 getClassNameAndIndex(inputType){
		let className:string = '';
		let indexNo:number = 0;
        if(inputType == "left"){
              className = "range-slider-tooltip-left";
		       indexNo = 5;
		}
		else{
			 className = "range-slider-tooltip-right";
			 indexNo = 6;
		}
		return {"className": className, "indexNo":indexNo};
	 }


	defaultConfigurations(){
		const container = this.rangeSlider.nativeElement;
		/** Default config */
		const rangeSlider_min = this.rangeSliderMinVal;
		const rangeSlider_max = this.rangeSliderMaxVal;
		/** Default config */
		container.querySelector('#RangeSlider .range-slider-val-left').style.width = `${rangeSlider_min + (100 - rangeSlider_max)}%`;
		container.querySelector('#RangeSlider .range-slider-val-right').style.width = `${rangeSlider_min + (100 - rangeSlider_max)}%`;

		container.querySelector('#RangeSlider .range-slider-val-range').style.left = `${rangeSlider_min}%`;
		container.querySelector('#RangeSlider .range-slider-val-range').style.right = `${(100 - rangeSlider_max)}%`;

		container.querySelector('#RangeSlider .range-slider-handle-left').style.left = `${rangeSlider_min}%`;
		container.querySelector('#RangeSlider .range-slider-handle-right').style.left = `${rangeSlider_max}%`;

		container.querySelector('#RangeSlider .range-slider-tooltip-left').style.left = `${rangeSlider_min}%`;
		container.querySelector('#RangeSlider .range-slider-tooltip-right').style.left = `${rangeSlider_max}%`;

		container.querySelector('#RangeSlider .range-slider-tooltip-left').style.backgroundColor = "#478B1A";
		container.querySelector('#RangeSlider .range-slider-tooltip-right').style.backgroundColor = "#FF0026";

		container.querySelector('#RangeSlider .range-slider-tooltip-left .range-slider-tooltip-text').innerText = rangeSlider_min;
		container.querySelector('#RangeSlider .range-slider-tooltip-right .range-slider-tooltip-text').innerText = rangeSlider_max;

		container.querySelector('#RangeSlider .range-slider-input-left').value = rangeSlider_min;
		container.querySelector('#RangeSlider .range-slider-input-right').value = rangeSlider_max;
	}

}
