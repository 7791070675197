import { Component, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnChanges {

  @Input() type: 'single' | 'range' = 'single';
  @Input() rangeType: 'day' | 'isoWeek' | 'month' | 'year' | 'custom';
  @Input() dateRange: { start: Date, end: Date };
  @Input() date: Date;
  @Input() minDate: Date;
  @Input() maxDate: Date;

  @Output() dateChange = new EventEmitter<Date>();
  @Output() dateRangeChange = new EventEmitter<{ start: Date, end: Date }>();

  public ngbDate: NgbDateStruct; // the date selected in bootstrap calendar
  public ngbDateRange: { start: NgbDateStruct, end: NgbDateStruct }; // the date range for display in bootstrap calendar
  public displayMonths = 1;
  public showBootstrapDatepicker = true;
  public hoveredDate: NgbDateStruct;
  public outsideDays: 'visible' | 'hidden' = 'visible'; // show/hide days outside of the current month in bootstrap calendar

  public selectedYear: number;
  public selectedMonth: number;
  public allMonths: string[] = moment.months().map(m => m.substring(0, 3));
  public allYears: number[] = Array.from({ length: 50 }, (v, k) => k + moment().year() - 10);


  ngOnChanges(changes: SimpleChanges) {
    if (changes.rangeType || changes.type) {
      // for custom type date range, show 2 months in the calendar
      this.displayMonths = this.type === 'range' && this.rangeType === 'custom' ? 2 : 1;
      // don't show the bootstrap datepicker for year and month range types
      this.showBootstrapDatepicker = this.type === 'single' || (this.rangeType !== 'year' && this.rangeType !== 'month');
      // hide days outside of the current month in bootstrap calendar for custom range type (as 2 months will be shown)
      this.outsideDays = (this.type === 'range' && this.rangeType === 'custom') ? 'hidden' : 'visible';

      this.selectedMonth = this.dateRange?.start ? moment(this.dateRange.start).month() : null;
      this.selectedYear = this.dateRange?.start ? moment(this.dateRange.start).year() : null;
    }

    if (changes.date && this.date) {
      this.ngbDate = this._convertDateToNgbDate(this.date);
    }

    if (changes.dateRange && this.dateRange) {
      this.ngbDateRange = {
        start: this._convertDateToNgbDate(this.dateRange.start),
        end: this._convertDateToNgbDate(this.dateRange.end)
      };

      this.selectedMonth = moment(this.dateRange.start).month();
      this.selectedYear = moment(this.dateRange.start).year();
    }
  }

  /**
   * updates the date range and emits the updated date range to parent component
   * compares the current date range to the new date range and only makes updates if necessary.
   * @param dateRange The new date range to update.
   */
  private _updateDateRange(dateRange: { start: Date, end: Date }) {
    // compare currentDateRange to newDateRange & don't make any updates unless necessary
    if (moment(dateRange.start).isSame(this.dateRange.start) && moment(dateRange.end).isSame(this.dateRange.end)) { return; }
    this.dateRange = dateRange;
    this.dateRangeChange.emit(dateRange);
  }

  /**
   * handles the date selection event in bootstrap datepicker
   * based on the type of date picker (date or range), and the range type (day, isoWeek, custom), the date selection can mean different things
   * 'month' and 'year' range types are not handled here, as they don't have a calendar view
   * @param ngbDate - The selected NgbDate.
   */
  onDateSelection(ngbDate: NgbDate) {
    // if we are picking a simple date (not range), simply check if the date is changed, and update the value to parent
    if (this.type === 'single') {
      const date = this._convertNgbDateToDate(ngbDate);
      if (moment(date).isSame(this.date)) { return; }

      this.ngbDate = ngbDate;
      this.date = date;
      return this.dateChange.emit(date);
    }

    // for range selection, this method gets called when user clicks on any date in the calendar,
    // and based on the range type, that click of any date can mean different things
    if (this.type === 'range') {
      switch (this.rangeType) {
        // for 'day' range type, the range is automatically set to the start and end of the clicked date
        case 'day':
          this.ngbDateRange = { start: ngbDate, end: ngbDate };
          return this._updateDateRange({ start: this._convertNgbDateToDate(ngbDate), end: moment(this._convertNgbDateToDate(ngbDate)).endOf('day').toDate() });

        // for 'isoWeek' range type, the range is automatically set to the start and end of the week of the clicked date
        case 'isoWeek':
          const startOfWeek = moment(this._convertNgbDateToDate(ngbDate)).startOf('isoWeek').toDate();
          const endOfWeek = moment(this._convertNgbDateToDate(ngbDate)).endOf('isoWeek').toDate();
          this.ngbDateRange = { start: this._convertDateToNgbDate(startOfWeek), end: this._convertDateToNgbDate(endOfWeek) };
          return this._updateDateRange({ start: startOfWeek, end: endOfWeek });

        // for 'custom' range type, the clicked date could be the start of the range or the end of the range
        // note: while building the range, the ngbDateRange object is used, the dateRange object itself is not touched until range is complete
        case 'custom':
          // if neither start nor end date is defined in ngbDateRange, then the clicked date is the start of the range
          if (!this.ngbDateRange.start && !this.ngbDateRange.end) {
            this.ngbDateRange.start = ngbDate;

          // if start date is defined but end date is not, and the clicked date is >= the defined start date, then the clicked date is the end of the range
          // at this point the range is complete, so we can update the dateRange object
          } else if (this.ngbDateRange.start && !this.ngbDateRange.end && (ngbDate.after(this.ngbDateRange.start) || ngbDate.equals(this.ngbDateRange.start))) {
            this.ngbDateRange.end = ngbDate;
            return this._updateDateRange({
              start: this._convertNgbDateToDate(this.ngbDateRange.start),
              end: moment(this._convertNgbDateToDate(this.ngbDateRange.end)).endOf('day').toDate()
            });

          // in any other case, the clicked date is the new start of the range and we explicitly mark the end date as null
          // this takes care of cases like user date range is already defined, but user wants to select a new range,
          // and also start date is already defined, but user clicks on a date that is < the current start date
          } else {
            this.ngbDateRange = { start: ngbDate, end: null };
          }
      }
    }
  }

  /**
   * handles the selection of a year, from the year / month pickers
   * @param year - The selected year.
   */
  onYearSelection(year: number) {
    this.selectedYear = year;
    let yearSelected =this.allYears.filter(values=> values == year);
    if(yearSelected.length == 0) {
      this.allYears.push(year)
    }
    switch (this.rangeType) {
      case 'month':
        if (!this.selectedMonth) { return; } // even though year has been picked, the month can still be undefined
        // set the range to the start and end of the selected month of the selected year
        return this._updateDateRange({
          start: moment([this.selectedYear, this.selectedMonth]).toDate(),
          end: moment([this.selectedYear, this.selectedMonth]).endOf('month').toDate()
        });
      case 'year':
        // set the range to the start and end of the selected year
        return this._updateDateRange({
          start: moment([this.selectedYear]).toDate(),
          end: moment([this.selectedYear]).endOf('year').toDate()
        });
    }
  }

  /**
   * handles the selection of a month from the month picker
   * @param month - The selected month (0-11)
   */
  onMonthSelection(month: number) {
    this.selectedMonth = month;
    if (!this.selectedYear) { return; } // even though month has been picked, the year can still be undefined

    // set the range to the start and end of the selected month of the selected year
    return this._updateDateRange({
      start: moment([this.selectedYear, this.selectedMonth]).toDate(),
      end: moment([this.selectedYear, this.selectedMonth]).endOf('month').toDate()
    });
  }

  /**
   * helper fn to convert a Date object to NgbDateStruct
   * @param date - the date object to be convereted
   */
  public _convertDateToNgbDate(date: Date): NgbDateStruct {
    if (!date || !date.getFullYear) { return null; }
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  /**
   * helper fn to convert an NgbDateStruct to Date object
   * @param ngbDate - the NgbDateStruct object to be convereted
   */
  public _convertNgbDateToDate(ngbDate: NgbDateStruct): Date {
    if (!ngbDate) { return null; }
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  /**
   * helper fn to check if a given date should be shown as 'selected' in bootstrap calendar
   * based on the type of date picker (date or range), and the range type (day, isoWeek, custom), the date selection can mean different things
   * @param ngbDate - the NgbDateStruct object to be convereted
   */
  isSelected(ngbDate: NgbDateStruct) {
    // for simple 'single' type picker, simply check if the date is the same as the selected date
    if (this.type === 'single') {
      return moment(this._convertNgbDateToDate(ngbDate)).isSame(this.date, 'day');

    } else {
      const date = this._convertNgbDateToDate(ngbDate);
      switch (this.rangeType) {
        // for 'day' and 'isoWeek' type range pickers, check if the date is between the start and end of the selected range
        // use '[]' as the last argument to include the start and end dates in the comparison
        case 'day':
        case 'isoWeek':
          return this.dateRange && moment(date).isBetween(this.dateRange.start, this.dateRange.end, null, '[]');
        // for 'custom' type date range, only the start and end dates of ngbDateRange are marked as selected, not the dates in between
        // the dates in between have the same style has 'hovered' dates
        case 'custom':
          return (this.ngbDateRange.start && moment(date).isSame(this._convertNgbDateToDate(this.ngbDateRange.start), 'day')) ||
            (this.ngbDateRange.end && moment(date).isSame(this._convertNgbDateToDate(this.ngbDateRange.end), 'day'));
      }

    }
  }

  /**
   * helper fn to check if a given date should be shown as 'hovered' in bootstrap calendar
   * based on the type of date picker (date or range), and the range type (day, isoWeek, custom), this can mean different things
   * @param ngbDate - the NgbDateStruct object to be convereted
   */
  isHovered(ngbDate: NgbDateStruct) {
    // for a simple 'single' type picker, return false for all
    // as the css styling is automatically applied for hovered dates using :hover pseudo class
    if (this.type === 'single') { return false; }

    switch (this.rangeType) {
      // for 'isoWeek' type range picker, check if the given date is in the same week as the hovered date
      case 'isoWeek':
        // if there is no hovered date, return false (as nothing is being hovered on, and days in any selected week all are shown as 'selected')
        if (!this.hoveredDate) { return false; }
        return moment(this._convertNgbDateToDate(ngbDate)).isSame(this._convertNgbDateToDate(this.hoveredDate), 'isoWeek');

      // for 'custom' type range picker, check if the given date is between the start and end of the selected range
      // but not including the start and end dates, as the start and end dates are already marked as 'selected'.
      // this should be applied both when the date range is fully defined, or when only the start date is defined, and user is hovering over a possible end date
      case 'custom':
        const date = this._convertNgbDateToDate(ngbDate);
        // check if date is between the already selected start and end dates
        if (this.ngbDateRange.end) {
          return moment(date).isBetween(this._convertNgbDateToDate(this.ngbDateRange.start), this._convertNgbDateToDate(this.ngbDateRange.end));

        // check if the date is between the already selected start date and the currently hovered date
        } else if (this.ngbDateRange.start && this.hoveredDate) {
          return moment(date).isBetween(this._convertNgbDateToDate(this.ngbDateRange.start), this._convertNgbDateToDate(this.hoveredDate));
        }
        // return false if neither start nor end date is selected or hovered over
        return false;
    }
  }

}
